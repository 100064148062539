<template>
  <div
    class="container noColor"
    v-title
    :data-title="$t('i18n.contractDetails')"
    id="contractDetails"
  >
    <el-card class="box-card">
      <template #header>
        <span>{{ $t("i18n.basicInfo") }}</span>
        <el-button class="fr" type="text" @click="$router.go(-1)">
          {{ $t("i18n.back") }}
        </el-button>
        <el-button
          v-has="'编辑安装合同'"
          class="fr"
          type="text"
          @click="setIsEdit(false)"
        >
          {{ $t("i18n.edit") }}
        </el-button>
      </template>
      <div class="text item">
        <jl-form
          :columns="basicColumns"
          @onSubmit="onSubmit"
          @resetForm="setIsEdit(true)"
        ></jl-form>
      </div>
    </el-card>
    <el-card class="box-card m-t">
      <template #header>
        <span>{{ $t("i18n.elevatorList") }}</span>
        <el-button class="fr" type="text" @click="newElevatorVisible = true">
          {{ $t("i18n.addElevator") }}
        </el-button>
      </template>
      <div class="text item">
        <jl-table
          @init="getElevatorList"
          @handleDelete="handleDelete"
          :tableData="elevatorData"
          :options="elevatorOptions"
          :columns="elevatorColumns"
          :operates="elevatorOperates"
          :total="elevatorTotal"
          :height="300"
        >
        </jl-table>
      </div>
    </el-card>
    <el-dialog
      :title="$t('i18n.addElevator')"
      v-model="newElevatorVisible"
      :close-on-click-modal="false"
      width="80%"
      destroy-on-close
    >
      <jl-table
        @init="getAddEleList"
        :tableData="addEleData"
        :options="addEleOptions"
        :columns="addEleColumns"
        :operates="addEleOperates"
        :total="addEleTotal"
        @handleSelectionChange="
          (val) => {
            handleSelectionChange(val, 'selectNewData');
          }
        "
        height="500"
      ></jl-table>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import jlForm from "../../components/form";
import jlTable from "../../components/table";
export default {
  name: "ContractDetail",
  components: {
    jlForm,
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const state = reactive({
      paging: {
        pageNumber: 1,
        pageSize: 10,
      },
      basicColumns: [
        {
          prop: "number",
          label: "maintenanceContractNo",
          type: "input",
          readonly: true,
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "signDate",
          label: "dateOfSigning",
          type: "date",
          readonly: true,
        },
        {
          prop: "projectName",
          label: "projectName",
          type: "input",
          readonly: true,
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "directSelling",
          label: "directSelling",
          type: "select",
          props: { label: "label", value: "value" },
          data: [{label:"是", value: true},{label:"否", value: false},],
          // readonly: true
        },
        {
          prop: "alignment",
          label: "debugOrNot",
          type: "select",
          props: { label: "label", value: "value" },
          data: [{label:"是", value: true},{label:"否", value: false},],
          // readonly: true
        },
        {
          prop: "projectLeader",
          label: "areaManager",
          type: "select",
          data: [],
          props: { label: "name", value: "id" },
          rules: [{ required: true, message: t("i18n.select") }],
        },
        {
          prop: "projectAddress",
          label: "address",
          type: "input",
          readonly: true,
        },
        {
          prop: "projectTelephone",
          label: "contractInformation",
          type: "input",
          readonly: true,
        },
        {
          prop: "firstPartyName",
          label: "partyA",
          type: "input",
          readonly: true,
        },
        {
          prop: "secondPartyName",
          label: "partyB",
          type: "input",
          readonly: true,
        },
        {
          prop: "firstPartySignature",
          label: "representativeA",
          type: "input",
          readonly: true,
        },
        {
          prop: "secondPartySignature",
          label: "representativeB",
          type: "input",
          readonly: true,
        },
        {
          prop: "firstPartyAddress",
          label: "addressA",
          type: "input",
          readonly: true,
        },
        {
          prop: "secondPartyAddress",
          label: "addressB",
          type: "input",
          readonly: true,
        },
        {
          prop: "firstPartyPhone",
          label: "telephoneA",
          type: "input",
          readonly: true,
        },
        {
          prop: "secondPartyPhone",
          label: "telephoneB",
          type: "input",
          readonly: true,
        },
        {
          prop: "manuscript",
          label: "originalcontract",
          type: "upload",
          action: "contracts",
          readonly: true,
          limit: "1",
          value: [],
          tip: "只能上传jpg/png/pdf文件",
        },
      ],
      elevatorData: [],
      elevatorTotal: 0,
      elevatorOptions: {
        isDelete: true,
        loading: false, // 是否添加表格loading加载动画
        highlightCurrentRow: true, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      elevatorColumns: [
        {
          prop: "number",
          label: "elevatorNo",
          align: "center",
          formatter: (row) => {
            return `<a>${row.number}</a>`;
          },
          method: (row) => {
            router.push({
              path: "/elevator/addEditElevator",
              query: { flag: 1, id: row.id },
            });
          },
        },
        {
          prop: "elevatorType",
          label: "elevatorType",
          align: "center",
        },
        {
          prop: "manufacturerName",
          label: "manufacturingCompany",
          align: "left",
        },
      ],
      elevatorOperates: {
        width: 100,
        fixed: "right",
        list: [],
      },
      contractDetail: "",
      newElevatorVisible: false, // 新增电梯弹窗
      addEleColumns: [
        {
          prop: "number",
          label: "elevatorNo",
          align: "center",
          search: true,
          type: "input",
        },
        {
          prop: "alias",
          label: "projectAlias",
          align: "left",
        },
        {
          prop: "project_name",
          label: "projectName",
          align: "left",
          width: 300,
          search: true,
          type: "input",
          required: true,
        },
        {
          prop: "elevator_type",
          label: "elevatorType",
          align: "center",
        },
      ],
      addEleOperates: {
        width: 100,
        fixed: "right",
        list: [
          {
            id: "1",
            label: "addElevator",
            icon: "el-icon-plus",
            class: "add",
            show: true,
            type: "outer",
            method: () => {
              addNewElevator();
            },
          },
        ],
      },
      addEleOptions: {
        loading: false, // 是否添加表格loading加载动画
        highlightCurrentRow: true, // 是否支持当前行高亮显示
        multiSelect: true, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      addEleTotal: 0,
      addEleData: [],
      selectNewData: [], // 选中未添加到合同的电梯
    });

    const init = async () => {
      let id = route.query.id;
      let { data } = await proxy.$api.system.installContractInfo(id);
      state.contractDetail = data;
      setIsEdit(true, data);
    };
    const initData = () => {
      getBillPerson();
    };
    
    // const getAllPersons = async (column) => {
    //   // 电梯工号 change
    //   //let obj = { elevatorId: val.id, name: "" };
    //   let { data } = await proxy.$api.maintenance.getBillPerson();
    //   // var index = state[column].findIndex((item) => {
    //   //   return item.prop === key;
    //   // });
    //   state[column][0].data = data;
    // };
    const getBillPerson = async () => {
      let { data } = await proxy.$api.maintenance.getBillPerson();
      
      //console.log(data)
      state.basicColumns.map((item) => {
        if (item.prop === "projectLeader") {
          item.data = data;
        }
      });
    };

    const onSubmit = async (row) => {
      console.log(row);
      if (state.contractDetail.number != row.number) {
        await proxy.$api.system.isExistContract({
           number: row.number ,
           projectName: row.projectName,
           });
      }
      editContract(row);
    };

    const editContract = async (params) => {
      
      let data = JSON.parse(JSON.stringify(params));
      if (data.signDate) {
        data.signDate = proxy.$defined.format(data.signDate);
      }
      if (Array.isArray(data.manuscript)) {
        let urls = [];
        data.manuscript.map((item) => {
          urls.push(item.url);
        });
        data.manuscript = urls.join(",");
      }
      data.id = route.query.id;
      await proxy.$api.system.editInstallContractOne(data.id, data);
      proxy.$defined.tip(t("i18n.edit") + t("i18n.success"), "success");
      init();
    };

    const getElevatorList = async (params) => {
      let id = route.query.id;
      const { data } = await proxy.$api.system.getInstallContractEle(
        id,
        params
      );
      state.elevatorData = data.records;
      state.elevatorTotal = data.total;
    };

    const addNewElevator = async () => {
      // 新增电梯到合同
      var ids = checkSelectEle("selectNewData");
      if (ids.length > 0) {
        await proxy.$api.system.addInstallContractEle(route.query.id, ids);
        proxy.$defined.tip(t("i18n.new") + t("i18n.success"), "success");
        state.newElevatorVisible = false;
        getElevatorList(state.paging);
      }
    };

    const checkSelectEle = (key) => {
      if (state[key].length > 0) {
        return state[key];
      } else {
        proxy.$defined.tip(t("i18n.selectElevators"));
        return "";
      }
    };

    const handleSelectionChange = (val, key) => {
      let elevatorIds = [];
      val.map((ele) => {
        elevatorIds.push(ele.id);
      });
      state[key] = elevatorIds;
    };

    const setIsEdit = (flag, data) => {
      state.basicColumns.map((item, index) => {
        item.readonly = flag;
        item.isUpdate = true;
        if (data && data[item.prop] != null && data[item.prop] != undefined) {
          if (item.type === "upload") {
            let files = [];
            data[item.prop].split(",").map((f) => {
              let name = f.split("/")[f.split("/").length - 1];
              let obj = {
                name: name,
                url: f,
              };
              files.push(obj);
            });
            item.value = files;
          }
          // else if (item.prop === "directSelling") {
          //   console.log(data);
          //   item.value = data[item.prop];
          //   state.basicColumns[index].value = data[item.prop]?"是":"否";
          // }
          else {
            item.value = data[item.prop];
            state.basicColumns[index].value = data[item.prop];
          }
        }
      });
    };

    const getAddEleList = async (params) => {
      // 加载未添加的电梯
      let id = route.query.id;
      params.eleNumber = params.number || "";
      params.projectName = params.projectName || "";
      const { data } = await proxy.$api.system.getInstallContractOtherEle(
        id,
        params
      );
      state.addEleData = data.records;
      state.addEleTotal = data.total;
    };

    const handleDelete = (row, pages) => {
      let id = route.query.id;
      proxy.$api.system.delInstallContractEle(id, row.id).then(() => {
        getElevatorList(pages);
        proxy.$defined.tip(t("i18n.delete") + t("i18n.success"), "success");
      });
    };

    init();
    initData();
    return {
      ...toRefs(state),
      onSubmit,
      getElevatorList,
      handleSelectionChange,
      setIsEdit,
      addNewElevator,
      getAddEleList,
      handleDelete,
    };
  },
};
</script>

<style lang="scss">
#contractDetails {
  .form-button {
    width: 100%;
    margin: 0;
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  #contractDetails {
    margin-top: -20px;
  }
}
</style>
